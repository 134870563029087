import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { B2B_STATION_CHARGING_HISTORY, DOWNLOAD_B2B_STATION_CHARGING_HISTORY, DOWNLOAD_METER_VALUES } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { roundOfDigit } from 'components/common/utils';
//import { millisecondsToHourMinute, roundOfDigit } from 'components/common/utils';
import { BsDownload } from 'react-icons/bs';
import { IoSearchOutline } from 'react-icons/io5';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import fileDownload from 'js-file-download';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';

const B2BStationChargingHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [cityLimit, setCityLimit] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [allB2bStationChargingHistoryData, setAllB2bStationChargingHistoryData] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [changeStation, setChangeStation] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');

  const b2bStationChargingHistoryList = useSelector((state) => state.b2bStationChargingHistory.b2bStationChargingHistory);
  const isLoader = useSelector((state) => state.b2bStationChargingHistory.isLoading);
  const page = useSelector((state) => state.b2bStationChargingHistory.page);
  const totalData = useSelector((state) => state.b2bStationChargingHistory.total);
  const limit = useSelector((state) => state.b2bStationChargingHistory.limit);
  const totalPages = useSelector((state) => state.b2bStationChargingHistory.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');
  const [selectedOption, setSelectedOption] = useState('station_name');
  const searchOptions = [
    { label: 'OCPP ID', value: 'ocpp_id' },
    { label: 'Station Name', value: 'station_name' },
  ];

  const b2bStationChargingHistory = useCallback(
    (value) => {
      if (searchText) {
        const b2bStationChargingHistoryData = {
          ...value,
          type: 'Private',
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          status: 'completed',
          search: {
            [selectedOption]: searchText,
          },
          is_emsp_based_booking: false,
        };
        dispatch({
          type: B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: b2bStationChargingHistoryData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          type: 'Private',
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          status: 'completed',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          type: 'Private',
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          status: 'completed',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText, selectedOption]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          state: '',
          city: '',
        };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        b2bStationChargingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        b2bStationChargingHistory(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        b2bStationChargingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        b2bStationChargingHistory(data);
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        b2bStationChargingHistory(data);
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        b2bStationChargingHistory(data);
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        b2bStationChargingHistory(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        b2bStationChargingHistory(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
      };
      b2bStationChargingHistory(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      b2bStationChargingHistory(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText, selectedOption]);

  // useEffect(() => {
  //   setAllB2bStationChargingHistoryData(
  //     _.filter(
  //       b2bStationChargingHistoryList,
  //       (item) =>
  //         startsWith(_.get(item, 'charger.charging_station.country'), searchText) ||
  //         startsWith(_.get(item, 'charger.charging_station.state'), searchText) ||
  //         startsWith(_.get(item, 'charger.charging_station.city'), searchText) ||
  //         startsWith(_.get(item, 'charger.charger_id'), searchText) ||
  //         startsWith(_.get(item, 'vehicle.model'), searchText) ||
  //         startsWith(_.get(item, 'vehicle.vin_num'), searchText) ||
  //         startsWith(roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3).toString(), searchText) ||
  //         startsWith(millisecondsToHourMinute(_.get(item, 'time_taken')).toString(), searchText) ||
  //         startsWith(_.get(item, 'idTag'), searchText) ||
  //         startsWith(_.get(item, 'StopSoC').toString(), searchText) ||
  //         startsWith(_.get(item, 'StartSoC').toString(), searchText) ||
  //         startsWith(_.get(item, 'meterstart').toString(), searchText) ||
  //         startsWith(_.get(item, 'meterstop').toString(), searchText) ||
  //         startsWith(_.get(item, 'transaction_id'), searchText)
  //     )
  //   );
  // }, [searchText, b2bStationChargingHistoryList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  const handleSearch = () => {
    b2bStationChargingHistory({});
  };

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      b2bStationChargingHistory(data);
    } else {
      b2bStationChargingHistory();
    }
  }, []);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && b2bStationChargingHistory(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        b2bStationChargingHistory(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && b2bStationChargingHistory(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        b2bStationChargingHistory(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All' && advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = {
            ...advanceFilterData,
            charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
            state: '',
            city: '',
          };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          b2bStationChargingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          b2bStationChargingHistory(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          b2bStationChargingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          b2bStationChargingHistory(data);
        } else if (advanceFilterData.state === 'All') {
          const filterData = { ...advanceFilterData, state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          b2bStationChargingHistory(data);
        } else if (advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          b2bStationChargingHistory(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          b2bStationChargingHistory(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          b2bStationChargingHistory(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        b2bStationChargingHistory(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: {
            [selectedOption]: searchText,
          },
          page: page.selected + 1,
        };
        b2bStationChargingHistory(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        b2bStationChargingHistory(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        b2bStationChargingHistory(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText, selectedOption]
  );

  // Download Excel Functionality

  const downloadB2BStationChargingHistory = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          state: '',
          city: '',
        };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          report: 'b2bhistory',
          type: 'Private',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          report: 'b2bhistory',
          type: 'Private',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          report: 'b2bhistory',
          type: 'Private',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          report: 'b2bhistory',
          type: 'Private',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          report: 'b2bhistory',
          type: 'Private',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          report: 'b2bhistory',
          type: 'Private',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          report: 'b2bhistory',
          type: 'Private',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          report: 'b2bhistory',
          type: 'Private',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        excel: true,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        report: 'b2bhistory',
        type: 'Private',
        status: 'completed',
        charging_station: userProfileStations,
      };
      dispatch({
        type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        report: 'b2bhistory',
        type: 'Private',
        status: 'completed',
      };
      dispatch({
        type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadB2BStationChargingHistory();
  };

  const onMeterValuesDownloadHandler = (item) => {
    dispatch({
      type: DOWNLOAD_METER_VALUES.REQUEST,
      payload: { id: item.id },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Private Station Charging History'}.xlsx`);
          }
        }
      },
    });
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);
  const search = (
    <div className="search-box-option">
      <Select options={searchOptions} value={selectedOption} onChange={(value) => setSelectedOption(value)} placeholder="Search Option" />
      <SearchBox
        preIcon={<BsSearch />}
        value={searchText}
        onChange={searchHandler}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
    </div>
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const totalStateData = useSelector((state) => state.state.total);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const totalCityData = useSelector((state) => state.cityByState.total);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { country: '', state: '', city: '', charging_station: '' };

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: { ...data, deleted: true } });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE.REQUEST, payload: { ...data, deleted: true } });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      access_type: 'Private',
    };
    dispatch({ type: GET_STATION_BY_CITY.REQUEST, payload: stationData });
  }, []);

  useEffect(() => {
    if (showAdvanceFilterPopup) {
      getStateByCountry({ country_name: country, limit: totalStateData });
    }
    if (cityLimit) {
      getCityByStateName({ state_names: state, limit: totalCityData });
    }
  }, [country, state, totalStateData, totalCityData, cityLimit]);

  useEffect(() => {
    getAllCountry();
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.b2bStationChargingHistory')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          {/* <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        /> */}
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment(new Date()).startOf('day')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <div className="advance-filter-btn">
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.stationName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.chargerID')}</span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.transactionId')}</span>
                                <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.chargingDate')}</span>
                                <span className="ico" onClick={() => handleSorting('schedule_datetime ', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.meterStartReading')}</span>
                                <span className="ico" onClick={() => handleSorting('meterstart', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.meterStopReading')}</span>
                                <span className="ico" onClick={() => handleSorting('meterstop', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.minTemp')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.maxTemp')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>
                                  {isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.sessionUnitConsumption')}
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.StartSoC')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.StopSoC')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>
                                  {isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.sessionStartDateTime')}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.sessionStopDateTime')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.sessionDuration')}</span>
                                <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.reasontoStop')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.idTag/OTP')}</span>
                                <span className="ico" onClick={() => handleSorting('idTag', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>
                                  {isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.vehicleRegistrationNumber')}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.make')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addB2BStationChargingHistory.downloadMeterValues')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(b2bStationChargingHistoryList) ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="empty-data-block">{t('addB2BStationChargingHistory.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(b2bStationChargingHistoryList, (item, key) => {
                              const connectorData = _.find(_.get(item, 'charger.plugs', []), { connector_id: item.connectorId });
                              const serial_num = limit * (page - 1) + key;
                              // const bookingStart = moment(_.get(item, 'booking_start', '-')).toISOString();
                              // const bookingStop = moment(_.get(item, 'booking_stop', '-')).toISOString();
                              // // const bookingDuration = isNaN(moment(bookingStop).diff(moment(bookingStart)))
                              // //   ? '00:00:00'
                              // //   : moment(bookingStop).diff(moment(bookingStart));

                              // function calculateTimeDifference(start, end) {
                              //   const diffInMilliseconds = Math.abs(end - start);
                              //   const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
                              //     .toString()
                              //     .padStart(2, '0');
                              //   const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
                              //     .toString()
                              //     .padStart(2, '0');
                              //   const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)
                              //     .toString()
                              //     .padStart(2, '0');

                              //   return { hours, minutes, seconds };
                              // }
                              // const startDate = new Date(bookingStart);
                              // const endDate = new Date(bookingStop);
                              // const timeDifference = calculateTimeDifference(startDate, endDate);

                              function calculateTimeDifference(start, end) {
                                const startTime = moment(start);
                                const stopTime = moment(end);

                                const timeDiffInMS = stopTime - startTime;

                                const seconds = moment.duration(timeDiffInMS).seconds();
                                const minutes = moment.duration(timeDiffInMS).minutes();
                                const hours = Math.trunc(moment.duration(timeDiffInMS).asHours());

                                if (Math.sign(timeDiffInMS) === -1) {
                                  return `-${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                } else {
                                  return `${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                }
                              }

                              return (
                                <tr key={`b2b-station-history-${serial_num}`}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <>
                                        {_.get(item, 'charger.charger_id', '')} ({_.get(connectorData, 'name', '0')})
                                      </>
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_id', '')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.schedule_datetime ? (
                                      moment(item.schedule_datetime).format('DD/MM/YYYY H:mm:ss')
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'meterstart', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'meterstop', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'min_temperature', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'max_temperature', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <>
                                        {' '}
                                        {isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart') / 1000)
                                          ? ''
                                          : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3)}
                                      </>
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'StartSoC', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'StopSoC', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.booking_start ? (
                                      moment(item.booking_start).format('DD/MM/YYYY H:mm:ss')
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.booking_stop ? (
                                      moment(item.booking_stop).format('DD/MM/YYYY H:mm:ss')
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <>{calculateTimeDifference(item.booking_start, item.booking_stop)}</>
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'stop_reason', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'idTag', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'vehicle.rto_no', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <>
                                        {_.get(item, 'vehicle.make', '')} ({_.get(item, 'vehicle.model', '')})
                                      </>
                                    )}
                                  </td>
                                  <td onClick={() => onMeterValuesDownloadHandler(item)}>
                                    <Button className="report-download-button">
                                      {isLoader ? <Skeleton height={15} width={100} /> : 'Download Excel'}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(b2bStationChargingHistoryList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.charging_station && delete values['charging_station'];
              if (values.charging_station === 'All' && values.state === 'All' && values.city === 'All') {
                const b2bStationChargingHistoryData = {
                  ...values,
                  state: '',
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                b2bStationChargingHistory(b2bStationChargingHistoryData);
              } else if (values.state === 'All' && values.charging_station === 'All') {
                const b2bStationChargingHistoryData = {
                  ...values,
                  state: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                b2bStationChargingHistory(b2bStationChargingHistoryData);
              } else if (values.city === 'All' && values.charging_station === 'All') {
                const b2bStationChargingHistoryData = {
                  ...values,
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                b2bStationChargingHistory(b2bStationChargingHistoryData);
              } else if (values.state === 'All' && values.city === 'All') {
                const b2bStationChargingHistoryData = { ...values, state: '', city: '' };
                b2bStationChargingHistory(b2bStationChargingHistoryData);
              } else if (values.city === 'All') {
                const b2bStationChargingHistoryData = { ...values, city: '' };
                b2bStationChargingHistory(b2bStationChargingHistoryData);
              } else if (values.state === 'All') {
                const b2bStationChargingHistoryData = { ...values, state: '' };
                b2bStationChargingHistory(b2bStationChargingHistoryData);
              } else if (values.charging_station === 'All') {
                const b2bStationChargingHistoryData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                b2bStationChargingHistory(b2bStationChargingHistoryData);
              } else {
                b2bStationChargingHistory(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.country')}
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectCountry')}
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val, limit: 999 });
                      setCountry(val);
                      setFieldValue(`country`, val);
                      setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.state')}
                    options={
                      !changeState
                        ? values.country &&
                          !_.isEmpty(allState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectState')}
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '' });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val });
                        setState(val);
                        setCityLimit(true);
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.city')}
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                          !_.isEmpty(allCityByState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allCityByState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder={t('placeHolder.selectCity')}
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getAllStation({ city_array: '' });
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`city`, '');
                      } else {
                        getAllStation({ city_array: val });
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue('city', val);
                      }
                      setFieldValue(`charging_station`, '');
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.station')}
                      options={
                        !changeStation
                          ? (!_.isEmpty(_.get(values, 'city')) && !_.isEmpty(stationList) && _.get(values, 'state') === 'All') ||
                            _.get(stationList, 'city') === _.get(values, 'city')
                            ? [
                                // { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return { label: station?.name, value: station?.id };
                                }),
                              ]
                            : [
                                // { label: 'All', value: 'All' },
                                ..._.map(
                                  stationList?.filter((o) => o.state === values.state[0]),
                                  (station) => {
                                    return { label: station?.name, value: station?.id };
                                  }
                                ),
                              ]
                          : !_.isEmpty(_.get(values, 'city')) &&
                            !_.isEmpty(_.get(values, 'state')) &&
                            values.country &&
                            _.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStation')}
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue(`charging_station`, val);
                        }
                      }}
                    />
                    <Select
                      label={t('Charger Id')}
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation
                            ? !_.isEmpty(stationList) && [
                                { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return station.chargers && station.chargers.length > 0
                                    ? { label: station.chargers[0].charger_id, value: station.chargers[0].id }
                                    : null;
                                }).filter(Boolean), // Remove null values
                              ]
                            : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                          : !changeStation
                          ? !_.isEmpty(stationList) && [
                              { label: 'All', value: 'All' },
                              ..._.map(stationList, (station) => {
                                return station.chargers && station.chargers.length > 0
                                  ? { label: station.chargers[0].charger_id, value: station.chargers[0].id }
                                  : null;
                              }).filter(Boolean), // Remove null values
                            ]
                          : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('Charger Id')}
                      name="charger_id"
                      isMulti
                      value={values.charger_id}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charger_id`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charger_id`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charger_id', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      b2bStationChargingHistory();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="clear--icon" />
                    {t('button.clear')}
                    {/* Reset */}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default B2BStationChargingHistory;
