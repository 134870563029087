import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Modal, Spinner, Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import * as _ from 'lodash';
import { CHARGER_STATUS, CHARGER_STATUS_DOWNLOAD, FETCH_CHARGER_STATUS_WIDGETS } from 'actions/chargerStatus';
// import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BiSort } from 'react-icons/bi';
import ReactPagination from 'components/general/ReactPagination';
import Select from 'components/inputs/Select';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import OffCanvas from 'components/inputs/OffCanvas';
import { GET_CHARGER_STATUS, MANAGE_CHARGER_STATUS_FILTER, UPDATE_STATUS_CODE } from 'components/common/constant';
// import './chargerstatus.css';
import { MdPowerOff } from 'react-icons/md';
import { BsLightningCharge } from 'react-icons/bs';
import { GiLogicGateAnd } from 'react-icons/gi';
import { RiChargingPile2Line } from 'react-icons/ri';
import fileDownload from 'js-file-download';
import { BsDownload } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import { RiEqualizerFill } from 'react-icons/ri';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { FETCH_CHARGE_SPEED } from 'actions/chargeSpeed';
import { FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import VRNForm from './vinForm';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { BsPlugin } from 'react-icons/bs';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import { GoPlug, GoStop } from 'react-icons/go';
import { FiZapOff } from 'react-icons/fi';
import { BsPlug } from 'react-icons/bs';

const ChargerStatus = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const history = useHistory();
  const { navigateTo, isAllowed } = useContext(NavContext);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  // const [allChargingStationData, setAllChargingStationData] = useState([]);
  const [currentTab, setCurrentTab] = useState(sessionStorage.getItem('tabActive') || 'normalCharger');

  const chargerStatusList = useSelector((state) => state.chargerStatus.chargerStatus);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const isLoader = useSelector((state) => state.chargerStatus.isLoading);
  const page = useSelector((state) => state.chargerStatus.page);
  const totalData = useSelector((state) => state.chargerStatus.total);
  const limit = useSelector((state) => state.chargerStatus.limit);
  const totalPages = useSelector((state) => state.chargerStatus.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const [showForm, setShowForm] = useState(false);
  const [gunId, setgunId] = useState('');
  const [chargeId, setChargeId] = useState('');
  const currentUserName = _.get(profileData, 'name', '');
  const userCompany = _.get(profileData, 'tenant.is_ocpi_charger_enabled');
  const [isChecked, setIsChecked] = useState(false);

  const manageChargerStatusFilter =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_CHARGER_STATUS_FILTER),
      (data) => data.name
    );

  const getChargerStatus =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_CHARGER_STATUS),
      (data) => data.name
    );

  const chargerStatus = useCallback(
    (value) => {
      if (currentTab === 'normalCharger') {
        if (searchText) {
          const chargerStatusData = {
            ...value,
            search: searchText,
            is_external_station: false,
          };
          dispatch({
            type: CHARGER_STATUS.REQUEST,
            payload: chargerStatusData,
            cb: (res) => {
              if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                onCloseAdvanceFilterPopup();
              }
            },
          });
        } else {
          const data = {
            ...value,
            is_external_station: false,
          };
          dispatch({
            type: CHARGER_STATUS.REQUEST,
            payload: data,
            cb: (res) => {
              if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                onCloseAdvanceFilterPopup();
              }
            },
          });
        }
      } else {
        if (searchText) {
          const chargerStatusData = {
            ...value,
            search: searchText,
            is_external_station: true,
          };
          dispatch({
            type: CHARGER_STATUS.REQUEST,
            payload: chargerStatusData,
            cb: (res) => {
              if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                onCloseAdvanceFilterPopup();
              }
            },
          });
        } else {
          const data = {
            ...value,
            is_external_station: true,
          };
          dispatch({
            type: CHARGER_STATUS.REQUEST,
            payload: data,
            cb: (res) => {
              if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                onCloseAdvanceFilterPopup();
              }
            },
          });
        }
      }
    },
    [searchText, currentTab]
  );

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
    sessionStorage.setItem('tabActive', tab);
  };

  const handleSearch = () => {
    chargerStatus({});
  };

  const handlevRNclick = (connectorId, chargerID) => {
    setShowForm(true);
    setChargeId(chargerID);
    setgunId(connectorId);
  };

  const getWidgets = useCallback(
    (isChecked) => {
      const isExternal = currentTab !== 'normalCharger';
      const data = {
        charging_station: userProfileStations,
        status: isChecked,
        is_external: isExternal,
      };
      dispatch({
        type: FETCH_CHARGER_STATUS_WIDGETS.REQUEST,
        payload: data,
      });
    },
    [userProfileStations, currentTab] // Add currentTab as a dependency
  );

  useEffect(() => {
    getWidgets(isChecked);
  }, [currentTab, getWidgets]); // Add currentTab as a dependency

  useEffect(() => {
    if (chargerFilter) {
      if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        chargerStatus(chargerFilter);
      } else {
        chargerStatus(chargerFilter);
      }
    } else {
      if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const sortedOrder = 'desc';
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
          charging_station: userProfileStations,
        };
        chargerStatus(data);
      } else {
        const sortedOrder = 'desc';
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
        };
        chargerStatus(data);
      }
    }
  }, [currentTab]);

  const downloadFile = () => {
    downloadChargerStatus();
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        chargerStatus(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        chargerStatus(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        chargerStatus(data);
      }
    },
    [chargerStatus]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const [chargerFilter, setChargerFilter] = useState('');

  const handlePageClick = useCallback(
    (page) => {
      setActiveIndex(null);
      const sortedOrder = sortByItem.order ? sortByItem.order : 'desc';
      const sortedItem = sortByItem.item ? sortByItem.item : 'status';
      let data = { page: page.selected + 1, sortBy: `${sortedOrder === 'asc' ? '' : '-'}${sortedItem}` };

      if (!_.isEmpty(advanceFilterData)) {
        data = { ...data, ...advanceFilterData };
      } else if (searchText) {
        // only use page number in this case
      } else if (chargerFilter) {
        data = { ...data, ...chargerFilter };
      }

      chargerStatus(data);
    },
    [sortByItem, advanceFilterData, userProfileStations, chargerFilter, currentTab]
  );

  const downloadChargerStatus = useCallback(() => {
    // Base payload for downloading charger status
    let basePayload = {
      excel: true,
      is_external_station: currentTab !== 'normalCharger',
    };

    // Add search keyword to the payload if available
    if (searchText) {
      basePayload = { ...basePayload, search: searchText };
    }

    // Add advanced filters if available
    if (!_.isEmpty(advanceFilterData)) {
      basePayload = { ...basePayload, ...advanceFilterData };
    }

    // Add charger availability status if available
    if (chargerFilter) {
      basePayload = { ...basePayload, ...chargerFilter };
    }

    // Dispatch the action with the constructed payload
    dispatch({
      type: CHARGER_STATUS_DOWNLOAD.REQUEST,
      payload: basePayload,
      cb: (res) => {
        if (res && _.get(res, 'status') === 200) {
          fileDownload(res.data, `${'Charger Status'}.xlsx`);
        }
      },
    });
  }, [currentTab, advanceFilterData, searchText, chargerFilter]);

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCity = useSelector((state) => state.city.city);
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  const allChargeSpeed = useSelector((state) => state.chargeSpeed.chargeSpeed);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);

  const initialValues = !_.isEmpty(advanceFilterData)
    ? { ...advanceFilterData }
    : { country: '', state: '', city: '', oem: '', charger_type: '', is_active: '', charger_availibity: '', access_type: '' };

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getAllCountry();
    dispatch({ type: FETCH_OEM_VENDOR.REQUEST, payload: { limit: 999 } });
    dispatch({ type: FETCH_CHARGE_SPEED.REQUEST });
  }, []);

  const totalcharger = useSelector((state) => state.chargerStatus.totalChargers);
  const availableData = useSelector((state) => state.chargerStatus.available);
  const unavailableData = useSelector((state) => state.chargerStatus.unavailable);
  const inuseData = useSelector((state) => state.chargerStatus.inuse);
  const powerlossCount = useSelector((state) => state.chargerStatus.PowerLossCount);
  const availableCount = useSelector((state) => state.chargerStatus.availableCount);
  const chargingCount = useSelector((state) => state.chargerStatus.chargingCount);
  const faultedCount = useSelector((state) => state.chargerStatus.faultedCount);
  const finishingCount = useSelector((state) => state.chargerStatus.finishingCount);
  const preparingCount = useSelector((state) => state.chargerStatus.preparingCount);
  const emergencyStopCount = useSelector((state) => state.chargerStatus.emergencyStopCount);
  const unavailableCount = useSelector((state) => state.chargerStatus.unavailableCount);
  const underVoltageCount = useSelector((state) => state.chargerStatus.underVoltage);
  const overVoltageCount = useSelector((state) => state.chargerStatus.overVoltage);

  const updateChargerStatus = (availability) => {
    if (currentTab === 'normalCharger') {
      const chargerStatusData = {
        charger_availibity: availability,
        charging_station: userProfileStations,
        is_external_station: false,
      };
      setActiveIndex(null);
      setChargerFilter(chargerStatusData);
      dispatch({
        type: CHARGER_STATUS.REQUEST,
        payload: chargerStatusData,
      });
    } else {
      const chargerStatusData = {
        charger_availibity: availability,
        charging_station: userProfileStations,
        is_external_station: true,
      };
      setActiveIndex(null);
      setChargerFilter(chargerStatusData);
      dispatch({
        type: CHARGER_STATUS.REQUEST,
        payload: chargerStatusData,
      });
    }
  };
  const getStatusFromCpoErrorCode = (status, cpoErrorCode) => {
    return cpoErrorCode.includes('Power Loss') ? 'Power Loss' : status;
  };

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);
    getWidgets(isChecked);
    chargerStatus();
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.chargerStatus')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div>
              <div className="tab-block">
                <div className="tab-nav-block">
                  <a
                    href="#"
                    className={`tab-nav__item ${currentTab === 'normalCharger' ? 'active' : ''}`}
                    onClick={(e) => {
                      setTab(e, 'normalCharger');
                    }}
                    data-content="tab-bookings"
                  >
                    {t('addChargerStatus.normalCharger')}
                  </a>
                  {userCompany === true && (
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'OCPICharger' ? 'active' : ''}`}
                      onClick={(e) => {
                        setTab(e, 'OCPICharger');
                      }}
                      data-content="tab-transactions"
                    >
                      {t('addChargerStatus.ocpiCharger')}
                    </a>
                  )}
                </div>
              </div>
            </div>
            {isAllowed('viewChargeSpotWiseStatus') ? (
              <div className="wise_status" title="Switch Between Charger Wise Data and Connector Wise Data">
                {t('addChargerStatus.chargerwise')}
                <ToggleSwitch id="toggle-switch" name="toggle-switch" checked={isChecked} onChange={(event) => handleChange(event)} />
                {t('addChargerStatus.ChargePoint')}
              </div>
            ) : (
              ''
            )}
            {isChecked ? (
              <>
                <div className="status-blocks">
                  <div className="status-block" title="Available Connectors">
                    <div className="breating-area">
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiChargingPile2Line className="icon-inner-color" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : availableCount || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.available')}</p>
                    </div>
                  </div>
                  <div className="status-block" title="Number of Connectors in Preparing State">
                    <div className="breating-area">
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiChargingPile2Line className="icon-inner-color" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : preparingCount || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.preparingCount')}</p>
                    </div>
                  </div>
                  <div className="status-block" title="Number of Connectors Currently In Use State">
                    <div className="breating-area">
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <GiLogicGateAnd className="icon-inner-color" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : chargingCount || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.charging')}</p>
                    </div>
                  </div>
                  <div className="status-block" title="Number of Connectors Currently In Finishing State">
                    <div className="breating-area">
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <BsLightningCharge className="icon-inner-color" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : finishingCount || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.finishingCount')}</p>
                    </div>
                  </div>
                  <div className="status-block" title="Number of Connectors Currently In Faulted State">
                    <div className="breating-area">
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <GiLogicGateAnd className="icon-inner-color" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : faultedCount || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.faultedCount')}</p>
                    </div>
                  </div>
                  <div className="status-block" title="Number of Connectors Currently In Unavailable State">
                    <div className="breating-area">
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <MdPowerOff className="icon-inner-color" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : unavailableCount || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.unavailable')}</p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="status-blocks">
                  <div className="status-block" onClick={() => updateChargerStatus()} title="Total Number of Chargers">
                    <div className="breating-area" style={{ background: '#ff8b001a' }}>
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiChargingPile2Line color="#FF8B00" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : totalcharger || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.total')}</p>
                    </div>
                  </div>
                  <div className="status-block" onClick={() => updateChargerStatus('In Use')} title="Number of Chargrers Currently In Use">
                    <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <GiLogicGateAnd color="#4975F2" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : inuseData || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.inUse')}</p>
                    </div>
                  </div>
                  <div className="status-block" onClick={() => updateChargerStatus('Available')} title="Number of Availabe Chargers">
                    <div className="breating-area" style={{ background: '#14ac7f1a' }}>
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <BsPlug color="#14AC7F" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : availableData || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.available')}</p>
                    </div>
                  </div>
                  <div className="status-block" onClick={() => updateChargerStatus('Unavailable')} title="Number of Unavailabe Chargers">
                    <div className="breating-area" style={{ background: '#ff8b001a' }}>
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <BsPlug color="#FF8B00" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : unavailableData || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.unavailable')}</p>
                    </div>
                  </div>
                  <div
                    className="status-block"
                    onClick={() => updateChargerStatus('PowerLoss')}
                    title="Number of Chargrers with No Power Availability"
                  >
                    <div className="breating-area" style={{ background: '#be210b21' }}>
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <FiZapOff color="#BE210B" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : powerlossCount || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.powerlossCount')}</p>
                    </div>
                  </div>
                  <div
                    className="status-block"
                    onClick={() => updateChargerStatus('Emergency stop pressed')}
                    title="Number of Chargrers where Pressed Emergency Stop Button"
                  >
                    <div className="breating-area" style={{ background: '#c9a11b1a' }}>
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <GoStop color="#C9A11B" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : emergencyStopCount || 0}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.emergencyStopCount')}</p>
                    </div>
                  </div>

                  <div
                    className="status-block"
                    onClick={() => updateChargerStatus('voltageVariance')}
                    title="Input Under Voltage & Input Over Voltage"
                  >
                    <div className="breating-area" style={{ background: '#ff8b001a' }}>
                      {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <GoPlug color="#FF8B00" size={35} />}
                    </div>
                    <div className="status-info">
                      <h1>{isLoader ? <Skeleton height={15} width={100} /> : Math.floor(underVoltageCount + overVoltageCount || 0)}</h1>
                      <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.voltageVariance')}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="data-report--main">
              <div className="data-report--block">
                <Card>
                  <div className="data-report__inner">
                    <div className="data-report__box">
                      <Row className="data-report__row">
                        <Col xl={9} lg={9} md={9} className="search_box_div">
                          {!_.isEmpty(manageChargerStatusFilter) || (!_.isEmpty(getChargerStatus) && !_.isEmpty(manageChargerStatusFilter)) ? (
                            <SearchBox
                              value={searchText}
                              preIcon={<BsSearch />}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSearch();
                                }
                              }}
                            />
                          ) : (
                            <SearchBox
                              value={searchText}
                              preIcon={<BsSearch />}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSearch();
                                }
                              }}
                            />
                          )}
                        </Col>

                        <Col xl={'3'} md={'3'} className="search_btn_download">
                          <div className="report-search-box" onClick={handleSearch} title="Search">
                            <Button className="report-search-button"> {t('addChargerStatus.search')}</Button>
                          </div>
                          {isAllowed('downloadChargerStatus') && (
                            <div className="excel-icon--block" onClick={downloadFile} title="Download">
                              {isLoading ? (
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                </div>
                              ) : (
                                <div className="report-excel-icon">
                                  <BsDownload title="Download" size={28} color={'#FFFFFF'} />
                                </div>
                              )}
                            </div>
                          )}
                          <div className="advance-filter-icon" title="Open Advance Filter">
                            <RiEqualizerFill size={28} color={'#FFFFFF'} onClick={() => setShowAdvanceFilterPopup(true)} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="data-report__table">
                      <div className="table-responsive">
                        <table className="record-list-table chargerstatus-list-table" id="table-to-xls">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.stationName')}</span>
                                  <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.ocppID')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.connectors')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.cpoCode')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.status')}</span>
                                  <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.lastUpdated')}</span>
                                  <span className="ico" onClick={() => handleSorting('updatedAt', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              {currentTab === 'normalCharger' ? <th colSpan={2}></th> : ''}
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                              </tr>
                            ) : _.isEmpty(chargerStatusList) ? (
                              <tr>
                                <td colSpan={6} className="border-0">
                                  <div className="empty-data-block">{t('addChargerStatus.noData')}</div>
                                </td>
                              </tr>
                            ) : (
                              !(isLoader || _.isUndefined(isLoader)) &&
                              _.map(chargerStatusList, (item0, index0) => {
                                if (_.get(item0, 'chargers').length > 0) {
                                  return _.map(_.get(item0, 'chargers'), (item1, index1) => {
                                    const globalIndex = parseInt('' + index0 + index1);
                                    const serial_num = limit * (page - 1) + index1;
                                    const isPowerLoss = _.get(item1, 'charger_status', '') === 'PowerLoss';
                                    return (
                                      <React.Fragment key={`charger-status-${serial_num}`}>
                                        <div className="charger-status-tr">
                                          <tr>
                                            <td
                                              className="station-link"
                                              onClick={() =>
                                                navigateTo(`/stations/${_.get(item0, 'id', '')}`, { state: { backTo: 'chargerStatus' } })
                                              }
                                            >
                                              <RiChargingPile2Line color="#3c7cdd" size={35} />
                                              {isLoader ? <Skeleton height={15} width={100} /> : <span> {_.get(item0, 'name', '')}</span>}
                                            </td>
                                            <td
                                              className={currentTab !== 'OCPICharger' ? 'charger-link' : ''}
                                              onClick={
                                                currentTab !== 'OCPICharger'
                                                  ? () => navigateTo(`/charger/${_.get(item1, 'id', '')}`, { state: { backTo: 'chargerStatus' } })
                                                  : null
                                              }
                                            >
                                              {isLoader ? <Skeleton height={15} width={100} /> : _.get(item1, 'charger_id', '')}
                                            </td>
                                            <td>
                                              <div className="connector-wrapper">
                                                {_.map(_.get(item1, 'plugs'), (item2, index2) => {
                                                  const isCharging = _.get(item2, 'status', '') === 'Charging';
                                                  const isPreparing = _.get(item2, 'status', '') === 'Preparing';
                                                  const isFinishing = _.get(item2, 'status', '') === 'Finishing';
                                                  const isAvailable = _.get(item2, 'status', '') === 'Available';
                                                  const isUnavailable = _.get(item2, 'status', '') === 'Unavailable';
                                                  const isFaulted = _.get(item2, 'status', '') === 'Faulted';
                                                  const name = _.get(item2, 'name', '');
                                                  const hasMoreThanOneLetter = name.length > 1;

                                                  let className = 'connector-circle';
                                                  if (isCharging) {
                                                    className += ' charging';
                                                  } else if (isAvailable) {
                                                    className += ' available';
                                                  } else if (isUnavailable && isPowerLoss) {
                                                    className += ' PowerLoss';
                                                  } else if (isFaulted && isPowerLoss) {
                                                    className += ' PowerLoss';
                                                  } else if (isPreparing || isFinishing) {
                                                    className += ' preparing';
                                                  } else {
                                                    className += ' unavailable';
                                                  }

                                                  if (hasMoreThanOneLetter) {
                                                    className += ' more-than-one-letter';
                                                  }

                                                  return (
                                                    <div key={index2} className={className}>
                                                      {isLoader ? <Skeleton height={15} width={100} /> : <BsPlugin size={28} />}
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="charger-link">
                                                {isLoader ? (
                                                  <Skeleton height={15} width={100} />
                                                ) : (
                                                  _.get(item1, 'plugs', [])
                                                    .map((plug) => plug.cpoErrorCode)
                                                    .find((cpoErrorCode) => cpoErrorCode) || '-'
                                                )}
                                              </div>
                                            </td>
                                            <td>
                                              <div className={`status_charger status-${_.get(item1, 'charger_status')}`}>
                                                {isLoader ? <Skeleton height={15} width={100} /> : _.get(item1, 'charger_status', '')}
                                              </div>
                                            </td>
                                            <td>
                                              {isLoader ? (
                                                <Skeleton height={15} width={100} />
                                              ) : (
                                                moment
                                                  .utc(_.get(item0, `chargers[${index1}].updatedAt`, ''))
                                                  .local()
                                                  .format('DD/MM/YYYY H:mm:ss')
                                              )}{' '}
                                            </td>
                                            {currentTab === 'normalCharger' ? (
                                              <td className="dropdown-link" colSpan={2}>
                                                {activeIndex === globalIndex ? (
                                                  <FaChevronUp size={28} onClick={() => setActiveIndex(null)} title="Collapse" />
                                                ) : (
                                                  <FaChevronDown size={28} onClick={() => setActiveIndex(globalIndex)} title="Expand" />
                                                )}
                                              </td>
                                            ) : (
                                              ''
                                            )}
                                          </tr>
                                          {activeIndex === globalIndex && (
                                            <tr className="sub-table">
                                              <React.Fragment>
                                                <table>
                                                  {/* sub-table-head-row */}
                                                  <tr className="sub-table-head-row" style={{ backgroundColor: '#141f29' }}>
                                                    {/* <th>
                                                    <div className="sorting">
                                                      <span>{isLoader ? <Skeleton height={15} width={100} /> : 'ID'}</span>
                                                    </div>
                                                  </th> */}
                                                    <th>
                                                      <div className="sorting">
                                                        <span>
                                                          {isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.connectors')}
                                                        </span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.status')}</span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>
                                                          {isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.errorCode')}
                                                        </span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>
                                                          {isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.venderErrorCode')}
                                                        </span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>
                                                          {isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.errorInfo')}
                                                        </span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.cpoCode')}</span>
                                                      </div>
                                                    </th>
                                                    {isAllowed('viewVRN') ? (
                                                      <th>
                                                        <div>
                                                          <span>
                                                            {isLoader ? <Skeleton height={15} width={100} /> : t('addChargerStatus.action')}
                                                          </span>
                                                        </div>
                                                      </th>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </tr>
                                                  {/* sub-table-body-row */}
                                                  {_.map(_.get(item1, 'plugs'), (item3, index3) => {
                                                    const isCharging = _.get(item3, 'status', '') === 'Charging';
                                                    const isPreparing = _.get(item3, 'status', '') === 'Preparing';
                                                    const isFinishing = _.get(item3, 'status', '') === 'Finishing';
                                                    const isAvailable = _.get(item3, 'status', '') === 'Available';
                                                    const isUnavailable = _.get(item3, 'status', '') === 'Unavailable';
                                                    const isFaulted = _.get(item3, 'status', '') === 'Faulted';
                                                    // const chargerId = _.get(item1, 'charger_id', '');
                                                    // const chargerpointId = chargerId.slice(1) + _.get(item3, 'connector_id', '');
                                                    const name = _.get(item3, 'name', '');
                                                    const hasMoreThanOneLetter = name.length > 1;
                                                    let connectorClassName = 'connector-circle';
                                                    if (isCharging || isPreparing || isFinishing) {
                                                      connectorClassName += 'connector-charging';
                                                    } else if (!isAvailable) {
                                                      connectorClassName += 'connector-unavailable';
                                                    }

                                                    let statusClassName = '';
                                                    if (isCharging) {
                                                      statusClassName = 'charging';
                                                    } else if (isAvailable) {
                                                      statusClassName = 'available';
                                                    } else if (isUnavailable && isPowerLoss) {
                                                      statusClassName = 'PowerLoss';
                                                    } else if (isFaulted && isPowerLoss) {
                                                      statusClassName = 'PowerLoss';
                                                    } else if (isPreparing || isFinishing) {
                                                      statusClassName = 'preparing';
                                                    } else {
                                                      statusClassName = 'unavailable';
                                                    }
                                                    return (
                                                      <tr key={index3} className="sub-table-body-row">
                                                        {/* <td style={{ backgroundColor: '#141f29' }}>{chargerpointId}</td> */}
                                                        <td style={{ backgroundColor: '#141f29' }}>
                                                          <div className="connector-wrapper">
                                                            <div
                                                              className={
                                                                hasMoreThanOneLetter
                                                                  ? `${connectorClassName} more-than-one-letter`
                                                                  : connectorClassName
                                                              }
                                                            >
                                                              {isLoader ? <Skeleton height={15} width={100} /> : name}
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td className={statusClassName} style={{ backgroundColor: '#141f29' }}>
                                                          <BsPlugin size={28} />
                                                          {isLoader ? (
                                                            <Skeleton height={15} width={100} />
                                                          ) : (
                                                            getStatusFromCpoErrorCode(_.get(item3, 'status', ''), _.get(item3, 'cpoErrorCode', ''))
                                                          )}
                                                        </td>
                                                        <td style={{ backgroundColor: '#141f29' }}>
                                                          {isLoader ? <Skeleton height={15} width={100} /> : _.get(item3, 'errorCode', '')}
                                                        </td>
                                                        <td style={{ backgroundColor: '#141f29' }}>
                                                          {isLoader ? <Skeleton height={15} width={100} /> : _.get(item3, 'errorMessage', '')}
                                                        </td>
                                                        <td style={{ backgroundColor: '#141f29' }}>
                                                          {isLoader ? <Skeleton height={15} width={100} /> : _.get(item3, 'errorInfo', '')}
                                                        </td>
                                                        <td style={{ backgroundColor: '#141f29' }}>
                                                          {isLoader ? <Skeleton height={15} width={100} /> : _.get(item3, 'cpoErrorCode', '')}
                                                        </td>
                                                        {isAllowed('viewVRN') ? (
                                                          <td style={{ backgroundColor: '#141f29' }}>
                                                            {isCharging ? (
                                                              <div
                                                                className="report-search-box"
                                                                onClick={() => handlevRNclick(_.get(item3, 'connector_id'), _.get(item1, 'id', ''))}
                                                              >
                                                                <Button className="report-search-button">{t('addChargerStatus.addVIN')}</Button>
                                                              </div>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </td>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </tr>
                                                    );
                                                  })}
                                                </table>
                                              </React.Fragment>
                                            </tr>
                                          )}
                                        </div>
                                      </React.Fragment>
                                    );
                                  });
                                }
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(chargerStatusList) && (
                      <>
                        <ReactPagination
                          currentPage={page}
                          limit={limit}
                          total={totalData}
                          handlePageClick={(pageVal) => handlePageClick(pageVal)}
                          totalPages={totalPages}
                          marginPagesDisplayed={1}
                          showDataCount={false} // Set this prop to false to hide the data count
                        />
                      </>
                    )}
                  </div>
                </Card>
              </div>
            </div>
            {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
          </div>
        </SkeletonTheme>
      </LayoutContainer>
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.oem && delete values['oem'];
              !values.charger_type && delete values['charger_type'];
              !values.access_type && delete values['access_type'];
              !values.is_active && delete values['is_active'];
              !values.charger_availibity && delete values['charger_availibity'];
              const filterData = {
                ...values,
                is_active: values.is_active === 'Not In Use' ? false : true,
              };
              chargerStatus(filterData);
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.country')}
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectCountry')}
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val });
                      setFieldValue(`country`, val);
                      setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.state')}
                    options={_.map(allState, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectState')}
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      getCityByState({ state_name: val });
                      const currentState = _.find(allState, { name: val });
                      setFieldValue(`state`, currentState.name);
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.city')}
                    options={
                      values.state &&
                      _.map(allCity, (item) => {
                        return { label: item.name, value: item.name };
                      })
                    }
                    placeholder={t('placeHolder.selectCity')}
                    name="city"
                    value={values.city}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: values.state })}
                    page={cityPage}
                    totalPage={cityTotalPage}
                    onChange={(val) => {
                      const currentCity = _.find(allCity, { name: val });
                      setFieldValue('city', currentCity.name);
                    }}
                  />

                  <Select
                    label={t('filters.oemName')}
                    options={_.map(allOemVendor, (item) => {
                      return { label: item.name, value: item.id };
                    })}
                    placeholder={t('placeHolder.selectOEMName')}
                    name="oem"
                    value={_.get(values, 'oem', '')}
                    onChange={(val) => setFieldValue(`oem`, val)}
                  />

                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.typeOfCharger')}
                      options={_.map(allChargeSpeed, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      placeholder={t('placeHolder.selectChargeSpeed')}
                      name="charger_type"
                      value={values.charger_type}
                      onChange={(val) => setFieldValue('charger_type', val)}
                    />
                    <Select
                      label={t('filters.chargerStatus')}
                      options={[
                        { label: 'Unavailable', value: 'Unavailable' },
                        { label: 'Available', value: 'Available' },
                        { label: 'In Use', value: 'In Use' },
                      ]}
                      placeholder={t('placeHolder.selectStatus')}
                      name="charger_availibity"
                      value={values.charger_availibity}
                      onChange={(val) => setFieldValue(`charger_availibity`, val)}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      chargerStatus();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
      {showForm && (
        <Modal show={showForm} size="lg" centered>
          <VRNForm onClose={() => setShowForm(false)} connectorId={gunId} chargerID={chargeId} onSuccess={chargerStatus} />
        </Modal>
      )}
    </>
  );
};

export default ChargerStatus;
