import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { DOWNLOAD_BRV_REPORT, DOWNLOAD_JV_REPORT, GET_BRV_REPORTS, GET_JV_REPORTS, GET_SMC_PAYMENT_GATEWAY, UPDATE_VOUCHER_NUMBER } from 'actions/smcGateway';

function* getSMCGateway(action) {
  try {
    const response = yield call(API.getSMCGateway, action.payload);
    yield put({ type: GET_SMC_PAYMENT_GATEWAY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_SMC_PAYMENT_GATEWAY.FAIL, payload: { error: e } });
  }
}

function* getBRVReports(action) {
  try {
    const response = yield call(API.getBRVReports, action.payload);
    yield put({ type: GET_BRV_REPORTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_BRV_REPORTS.FAIL, payload: { error: e } });
  }
}

function* getJVReports(action) {
  try {
    const response = yield call(API.getJVReports, action.payload);
    yield put({ type: GET_JV_REPORTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_JV_REPORTS.FAIL, payload: { error: e } });
  }
}

function* downloadBRVReport(action) {
  try {
    const response = yield call(API.downloadBRVReport, action.payload);
    yield put({ type: DOWNLOAD_BRV_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_BRV_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadJVReport(action) {
  try {
    const response = yield call(API.downloadJVReport, action.payload);
    yield put({ type: DOWNLOAD_JV_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_JV_REPORT.FAIL, payload: { error: e } });
  }
}

function* assignVoucherNumber(action) {
  try {
    const response = yield call(API.assignVoucherNumber, action.payload);
    yield put({ type: UPDATE_VOUCHER_NUMBER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VOUCHER_NUMBER.FAIL, payload: { error: e } });
  }
}



function* smcGateway() {
  yield takeLatest(GET_SMC_PAYMENT_GATEWAY.REQUEST, getSMCGateway);
  yield takeLatest(GET_BRV_REPORTS.REQUEST, getBRVReports );
  yield takeLatest(GET_JV_REPORTS.REQUEST, getJVReports );
  yield takeLatest(DOWNLOAD_BRV_REPORT.REQUEST, downloadBRVReport );
  yield takeLatest(DOWNLOAD_JV_REPORT.REQUEST, downloadJVReport);
  yield takeLatest(UPDATE_VOUCHER_NUMBER.REQUEST, assignVoucherNumber);

}

export default smcGateway;
