import React, { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import PropTypes from 'prop-types';
import { ObjectId } from 'bson';
import './Chartstyle.css';
import { BASE_URL, CHART_IDS } from './chartConstants';

const Heatmaptest = ({ rolePermission, currentTenantId, currentStation, selectedStation }) => {
  const sdk = new ChartsEmbedSDK({
    baseUrl: BASE_URL,
  });
  const chartDiv = useRef(null);
  const [isReset, setIsReset] = useState(false); // Track whether the reset action is triggered or not

  const [filter, setFilter] = useState({
    tenant: { $eq: new ObjectId(currentTenantId) },
  });

  if (currentStation && currentStation.length > 0) {
    filter['chargers.charging_station'] = { $in: currentStation.map((id) => new ObjectId(id)) };
  }

  useEffect(() => {
    if (selectedStation && selectedStation.length > 0) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        'chargingstations._id': { $in: selectedStation.map((id) => new ObjectId(id)) },
      }));
    } else {
      setFilter((prevFilter) => ({
        ...prevFilter,
        'chargingstations._id': { $exists: true },
      }));
    }
  }, [selectedStation]);

  const chart = sdk.createChart({
    chartId: CHART_IDS.HEATMAP,
    showAttribution: false,
    padding: 0,
    theme: 'dark',
    background: '$brand-primary',
    rolePermissions: {
      getHeatmapReport: rolePermission, // set the role permission
    },
    filter,
    autoRefresh: false,
    maxDataAge: 300,
  });

  Heatmaptest.propTypes = {
    rolePermission: PropTypes.array,
    currentTenantId: PropTypes.string,
    currentStation: PropTypes.array,
    selectedStation: PropTypes.array,
  };

  useEffect(() => {
    async function renderChart() {
      try {
        await chart.render(chartDiv.current);
        const data = await chart.getData(); // Get the chart data

        if (data.documents.length === 0) {
          chartDiv.current.innerHTML = "<div class='not-found'>No PCS Utilization Report Found</div>";
        }
      } catch (err) {
        console.log('Error during Charts rendering.', err);
      }
    }

    renderChart();
  }, [filter]);

  useEffect(() => {
    if (isReset) {
      chartDiv.current.innerHTML = ''; // Clear the chart container when resetting
      setIsReset(false); // Reset isReset state
    }
  }, [isReset]);

  return <div className="chart-container" ref={chartDiv} style={{ height: 650, paddingTop: 15 }}></div>;
};

export default Heatmaptest;
