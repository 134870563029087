
// Mapping of country codes to their respective timezones
export const countryToTimezoneMap = {
    '1': 'America/New_York', // United States
    '44': 'Europe/London', // United Kingdom
    '91': 'Asia/Kolkata', // India
    '86': 'Asia/Shanghai', // China
    '81': 'Asia/Tokyo', // Japan
    '49': 'Europe/Berlin', // Germany
    '33': 'Europe/Paris', // France
    '39': 'Europe/Rome', // Italy
    '61': 'Australia/Sydney', // Australia
    '7': 'Europe/Moscow', // Russia
    '55': 'America/Sao_Paulo', // Brazil
    '27': 'Africa/Johannesburg', // South Africa
    '34': 'Europe/Madrid', // Spain
    '82': 'Asia/Seoul', // South Korea
    '62': 'Asia/Jakarta', // Indonesia
    '60': 'Asia/Kuala_Lumpur', // Malaysia
    '65': 'Asia/Singapore', // Singapore
    '64': 'Pacific/Auckland', // New Zealand
    '66': 'Asia/Bangkok', // Thailand
    '52': 'America/Mexico_City', // Mexico
    '31': 'Europe/Amsterdam', // Netherlands
    '46': 'Europe/Stockholm', // Sweden
    '41': 'Europe/Zurich', // Switzerland
    '36': 'Europe/Budapest', // Hungary
    '30': 'Europe/Athens', // Greece
    '20': 'Africa/Cairo', // Egypt
    '971': 'Asia/Dubai', // UAE
    '351': 'Europe/Lisbon', // Portugal
    '43': 'Europe/Vienna', // Austria
    '90': 'Europe/Istanbul', // Turkey
    '48': 'Europe/Warsaw', // Poland
    '63': 'Asia/Manila', // Philippines
    '94': 'Asia/Colombo', // Sri Lanka
    '258': 'Africa/Maputo', // Mozambique
    '855': 'Asia/Phnom_Penh', // Cambodia
    '673': 'Asia/Brunei', // Brunei
    '354': 'Atlantic/Reykjavik', // Iceland
    '353': 'Europe/Dublin', // Ireland
    '992': 'Asia/Dushanbe', // Tajikistan
    '998': 'Asia/Tashkent', // Uzbekistan
    '380': 'Europe/Kyiv', // Ukraine
    '372': 'Europe/Tallinn', // Estonia
    '233': 'Africa/Accra', // Ghana
    default: 'UTC'
  };
