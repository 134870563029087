import React, { useState, useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { GrDownload } from 'react-icons/gr';
import { SiMicrosoftexcel } from 'react-icons/si';
import { Col, Row, Spinner, Modal, Button } from 'react-bootstrap';
import DropDown from 'components/inputs/DropDown';
import { AiFillCaretDown } from 'react-icons/ai';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { DOWNLOAD_GST_VOUCHER } from 'actions/dataReport';
import fileDownload from 'js-file-download';
import { GET_BRV_REPORTS, GET_JV_REPORTS, GET_SMC_PAYMENT_GATEWAY, DOWNLOAD_BRV_REPORT, DOWNLOAD_JV_REPORT } from 'actions/smcGateway';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { MdClose } from 'react-icons/md';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';

const JobVoucher = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [report, setReport] = useState('Select_Voucher');
  const [fieldValue, setFieldValue] = useState('Select Gateway');
  const [gateway, setGateway] = useState(null);
  const [excelLoading, setExcelLoading] = useState(false);

  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedReportId, setSelectedReportId] = useState(null); // State for the selected report ID
  const [voucherNumber, setVoucherNumber] = useState(''); // State for voucher number

  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const reportList = useSelector((state) => state.smcReports.reports);
  
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment(new Date()).tz(countryTimezone).startOf('day').utc());
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day').utc());

  const handleAssignVoucherClick = (reportId) => {
    setSelectedReportId(reportId); // Set the selected report ID
    setShowModal(true); // Show the modal
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setVoucherNumber(''); // Reset the voucher number
  };
  const handleVoucherSubmit = () => {
    dispatch({
      type: 'UPDATE_VOUCHER_NUMBER',
      payload: {
        reportId: selectedReportId,
        voucherNumber,
      },
    });
    window.location.reload()
    handleModalClose(); // Close the modal after dispatching
  };

  const filterData = [
    { label: 'INCOME VOUCHER', value: 'JV' },
    { label: 'BRV WALLET DEPOSIT', value: 'BRV' },
    { label: 'GST_REPORT', value: 'GST' },
  ];

  const initialValues = {
    from: moment(startDate).tz(countryTimezone).utc(),
    to: moment(endDate).tz(countryTimezone).utc(),
    report: report,
    gatewayId: gateway,
  };

  const getSMCGateway = useCallback((data) => {
    dispatch({ type: GET_SMC_PAYMENT_GATEWAY.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getSMCGateway();
  }, []);

  const downloadInvoiceReport = (data) => {
    const payload = {
      ...data,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
      report: initialValues.report,
    };

    if (report === 'BRV') {
      if (fieldValue === 'ICICI BANK') {
        payload.bank_name = 'ICICI';
        payload.gatewayId = 2;
      } else if (fieldValue === 'BillDesk (Kotak Bank)') {
        payload.bank_name = 'Kotak';
        payload.gatewayId = 1;
      } else if (fieldValue === 'HDFC BANK') {
        payload.bank_name = 'HDFC';
      } else if (fieldValue === 'All Bank Detail') {
        payload.bank_name = 'All';
      }
    }

    const actionType = report === 'BRV' ? GET_BRV_REPORTS.REQUEST : GET_JV_REPORTS.REQUEST;

    dispatch({ type: actionType, payload });
  };

  const downloadGSTReport = (data) => {
    const payload = {
      ...data,
      from: moment(startDate).tz(countryTimezone).utc(),
      to: moment(endDate).tz(countryTimezone).utc(),
      report: initialValues.report,
    };

    dispatch({
      type: DOWNLOAD_GST_VOUCHER.REQUEST,
      payload: payload,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `gst_report.${payload.type ? 'xlsx' : 'pdf'}`);
            setExcelLoading(false);
          }
        }
      },
    });
  };

  const downloadReport = (reportId, type) => {
    const actionType = 
  ['BRV', 'Diff BRV', 'BPV'].includes(type)
    ? DOWNLOAD_BRV_REPORT.REQUEST
    : DOWNLOAD_JV_REPORT.REQUEST;

    const payload = { reportId };

    dispatch({
      type: actionType,
      payload,
      cb: (res) => {
        if (res && _.get(res, 'status') === 200) {
          fileDownload(res.data, `${type}_report_${reportId}.pdf`);
        }
      },
    });
  };


  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.jobVoucher')} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col lg={'auto'}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).startOf('day')}
                          initialValueOfEndDate={moment(new Date()).endOf('day')}
                          closeOnSelect={true}
                        />
                      </div>
                    </Col>
                    <Col lg={'auto'}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="job-voucher-filter-block">
                              <div className="job-voucher-filter-sec">
                                <span className="job-voucher-filter-label">{_.startCase(initialValues.report)}</span>
                              </div>
                              <div className="job-voucher-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="customerBooking-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              className="item"
                              onClick={() => {
                                setReport(item.value);
                              }}
                            >
                              {_.startCase(item.label)}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col>
                    {report === 'BRV' && (
                      <Col lg={'auto'}>
                        <DropDown
                          popupClass="main-menu bottom scrollable"
                          trigger={
                            <>
                              <div className="job-voucher-filter-block">
                                <div className="job-voucher-filter-sec">
                                  <span className="job-voucher-filter-label">{fieldValue}</span>
                                </div>
                                <div className="job-voucher-arrow__icon">
                                  <AiFillCaretDown />
                                </div>
                              </div>
                            </>
                          }
                          triggerClass="customerBooking-filter-dropdown"
                        >
                          {[
                            { name: 'BillDesk (Kotak Bank)', id: 1 },
                            { name: 'ICICI BANK', id: 2 },
                            { name: 'HDFC BANK', id: 3 },
                            { name: 'All Bank Detail', id: null },
                          ].map((bank, index) => (
                            <ul key={`${index}`}>
                              <li
                                className="item"
                                onClick={() => {
                                  setFieldValue(bank.name);
                                  setGateway(bank.id); // Remove this line if 'All Bank Detail' shouldn't have gatewayId
                                }}
                              >
                                {bank.name}
                              </li>
                            </ul>
                          ))}
                        </DropDown>
                      </Col>
                    )}
                    {/* PDF Download Button */}
                    {report !== 'GST' && (
                      <Col lg={'auto'} className="excel-icon--block">
                        <div className="report-excel-icon">
                          <GrDownload
                            size={28}
                            color={'#3c7cdd'}
                            onClick={() => {
                              downloadInvoiceReport({});
                            }}
                          />
                        </div>
                      </Col>
                    )}
                    {/* Excel Download Button */}
                    {report === 'GST' && (
                      <Col lg={'auto'} className="excel-icon--block">
                        {excelLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <SiMicrosoftexcel
                              size={28}
                              color={'#5dad5d'}
                              onClick={() => {
                                downloadGSTReport({ type: 'excel' });
                                setExcelLoading(true);
                              }}
                            />
                          </div>
                        )}
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </Card>
          </div>
          <div className="data-table-wrapper mt-4">
            <h5>{t('Report List')}</h5>
            <table className="record-list-table" id="table-to-xls">
              <thead>
                <tr>
                  <th>{t('Type')}</th>
                  <th>{t('Description')}</th>
                  <th>{t('Date Range')}</th>
                  <th>{t('Transaction ID')}</th>
                  <th>{t('Voucher Number')}</th>
                  <th>{t('Action')}</th>
                </tr>
              </thead>
              <tbody>
                {reportList && reportList.length > 0 ? (
                  reportList.map((item) => (
                    <tr key={item.reportId}>
                      <td>{item.type}</td>
                      <td>{item.description}</td>
                      <td>
                        {moment(item.dateRange.from).format('YYYY-MM-DD')} to{' '}
                        {moment(item.dateRange.to).format('YYYY-MM-DD')}
                      </td>
                      <td>{item.transactionId}</td>
                      <td>{item.voucherNumber}</td>
                      <td>
                        <button
                          className="job-report-button"
                          onClick={() => downloadReport(item.reportId, item.type)}
                        >
                          {t('Download')}
                        </button>
                      </td>
                      {!item.voucherNumber && (
                        <td>
                          <button
                            className="job-report-button responsive-button"
                            onClick={() => handleAssignVoucherClick(item.reportId)}
                          >
                            {t('Assign Voucher Number')}
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      {t('No reports available')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Modal size={'auto'} show={showModal} onHide={handleModalClose} centered className="job-modal">
            <Modal.Header>
              <div className='job-modal-header'>
                <Modal.Title>{t('Assign Voucher Number')}</Modal.Title>
                <button onClick={handleModalClose}>
                  <MdClose />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body className='job-modal-body'>
              <input
                type="text"
                className="form-control mt-2"
                value={voucherNumber}
                onChange={(e) => setVoucherNumber(e.target.value)}
                placeholder={t('Enter Voucher Number')}
              />
            </Modal.Body>
            <Modal.Footer className='job-modal-footer'>
              <Button onClick={handleVoucherSubmit} className='job-report-button'>
                {t('Submit')}
              </Button>
            </Modal.Footer>
          </Modal>

          <ToastContainer />

          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default JobVoucher;