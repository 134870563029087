import React from 'react';
import styled from '@emotion/styled';
import style from 'assets/style';
import PropTypes from 'prop-types';

const ErrorMessageStyle = styled('div')`
  font-size: ${style.pxToRem(12)};
  position: absolute;
  color: ${style.theme.colors.semantic.error};
  bottom: -${style.pxToRem(18)};
  white-space: nowrap;

  :first-letter {
    text-transform: uppercase;
  }
`;

const ErrorMessage = ({ children }) => <ErrorMessageStyle className="form-error">{children}</ErrorMessageStyle>;

ErrorMessage.propTypes = {
  children: PropTypes.node,
};
export default ErrorMessage;
