import { get  , post , downloadFile} from 'utils/api';

export const getSMCGateway = (data) => {
  return get(`/util/smcgateway`, data);
};

export const getBRVReports = (data) => {
  return post(`/report/getBRVReports`, data);
};

export const getJVReports = (data) => {
  return post(`/report/getJVReports`, data);
};

export const downloadBRVReport = (data) => {
  const id = data.reportId
  delete data['reportId'];
  return downloadFile(`/report/downloadBRV/${id}`, data);
};

export const downloadJVReport = (data) => {
  const id = data.reportId
  delete data['reportId'];
  return downloadFile(`/report/downloadJV/${id}`, data);
};

export const assignVoucherNumber = (data) =>{
  const id = data.reportId
  delete data['reportId'];
  return post(`/report/assignVouchernumber/${id}`, data)
}

export const smcGateway = {
  getSMCGateway,
  getBRVReports,
  getJVReports,
  downloadBRVReport,
  downloadJVReport,
  assignVoucherNumber
};
