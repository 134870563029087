import React, { useEffect, useRef } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import PropTypes from 'prop-types';
import { ObjectId } from 'bson';
import './Chartstyle.css';
import { BASE_URL, CHART_IDS } from './chartConstants';

const ChargerStatuschart = ({ rolePermission = {}, currentTenantId, currentStation, isExternal }) => {
  const sdk = new ChartsEmbedSDK({
    baseUrl: BASE_URL,
  });
  const chartDiv = useRef(null);

  ChargerStatuschart.propTypes = {
    rolePermission: PropTypes.object.isRequired,
    currentTenantId: PropTypes.string.isRequired,
    currentStation: PropTypes.array.isRequired,
    isExternal: PropTypes.bool.isRequired,
  };

  const filter = {
    tenant: { $eq: new ObjectId(currentTenantId) },
  };

  if (currentStation && currentStation.length > 0) {
    filter['charging_station'] = { $in: currentStation.map((id) => new ObjectId(id)) };
  }

  if (isExternal) {
    filter['is_external_charger'] = isExternal;
  }

  const chart = sdk.createChart({
    chartId: CHART_IDS.CHARGERSTATUS,
    showAttribution: false,
    theme: 'dark',
    background: '$brand-primary',
    rolePermissions: {
      getWidgetChargerStatus: rolePermission, // set the role permission
    },
    filter,
    autoRefresh: false,
    maxDataAge: 300,
  });

  useEffect(() => {
    async function renderChart() {
      try {
        await chart.render(chartDiv.current);
      } catch (err) {
        console.log('Error during Charts rendering.', err);
      }
    }

    renderChart();
  }, [isExternal]);

  return <div className="chart-container" ref={chartDiv}></div>;
};

export default ChargerStatuschart;
