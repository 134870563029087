import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import { useParams } from 'react-router-dom';
import Card from 'components/inputs/Card';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import * as _ from 'lodash';
import Button from 'components/inputs/Button';
import { FETCH_CPO } from 'actions/partnerManagement';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { ADD_PARTY_IN_OCPI_PARTNER_GROUP } from 'actions/ocpiPartnerGroup';

const SelectPartyId = () => {
  const { partnerGroupId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);
  const allPartyId = useSelector((state) => state.partnerManagement.partnerDetails);
  const customerPage = useSelector((state) => state.customer.page);
  const customerTotalPage = useSelector((state) => state.customer.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const updateUser = useCallback(
    (userGroup) => {
      if (!userGroup) return; // Add a check to ensure the userGroup is defined
      const userData = {
        id: userGroup.value, // Use the id value from the selected option
        role: userGroup.role,
        partyId: userGroup.partyId,
        user_group: partnerGroupId,
      };

      dispatch({
        type: ADD_PARTY_IN_OCPI_PARTNER_GROUP.REQUEST,
        payload: userData,
        cb: (res) => {
          navigateTo('/party/' + partnerGroupId);
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            console.log('hreere');
            toast.success('Party added successfully');
            setTimeout(() => {}, 1000);
          }
        },
      });
    },
    [partnerGroupId, dispatch, navigateTo]
  );

  const getAllCustomer = useCallback(
    (data = {}) => {
      if (searchText) {
        const customerData = {
          ...data,
          limit: 999,
          search: searchText,
        };
        dispatch({ type: FETCH_CPO.REQUEST, payload: customerData });
      } else {
        dispatch({ type: FETCH_CPO.REQUEST, payload: { limit: 999 } });
      }
    },
    [searchText, dispatch]
  );

  useEffect(() => {
    getAllCustomer();
  }, [searchText, getAllCustomer]);

  const userSchema = Yup.object().shape({
    user_group: Yup.string().required(t('addUserForm.userGroup')),
  });

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('Select Party ID')} backTo={`/party/${partnerGroupId}`} />
        <div className="page-content-wrapper scrollable">
          <Card>
            <div className="user-form-body__block">
              <div className="user-form--block">
                <Formik
                  initialValues={{
                    user_group: '',
                  }}
                  validationSchema={userSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateUser(selectedOption); // Pass the full object
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Select
                            isRequired
                            label={t('Select Party Id')}
                            options={_.map(allPartyId, (item) => {
                              return {
                                label: _.get(item, 'partyId', 'Guest User'),
                                value: item.id,
                                partyId: _.get(selectedOption, 'partyId', ''),
                                role: _.get(selectedOption, 'role', ''),
                              };
                            })}
                            placeholder={t('Select Party Id')}
                            name="user_group"
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getAllCustomer}
                            page={customerPage}
                            totalPage={customerTotalPage}
                            value={values.user_group}
                            onChange={(val) => {
                              const selectedOption = _.find(allPartyId, { id: val });
                              setFieldValue('user_group', val); // Set only the value in Formik state
                              setSelectedOption({
                                label: _.get(selectedOption, 'partyId', ''),
                                value: selectedOption.id,
                                partyId: _.get(selectedOption, 'partyId', ''),
                                role: _.get(selectedOption, 'role', ''),
                              }); // Store the full object in local state
                            }}
                            onInputChange={(text) => setSearchText(text)}
                            error={errors.user_group && touched.user_group ? errors.user_group : null}
                          />
                        </Col>
                      </Row>
                      <div className="user-form-btn__block">
                        <Button type="submit" disabled={isSubmitting} className="user-form-btn">
                          {t('Select Party Id')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        </div>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default SelectPartyId;
