import React, { useEffect, useRef } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import PropTypes from 'prop-types';
import { ObjectId } from 'bson';
import './Chartstyle.css';
import { BASE_URL, CHART_IDS } from './chartConstants';

const TotalUsers = ({ rolePermission, currentTenantId }) => {
  const sdk = new ChartsEmbedSDK({
    baseUrl: BASE_URL,
  });
  const chartDiv = useRef(null);

  const chart = sdk.createChart({
    chartId: CHART_IDS.TOTAL_USERS,
    showAttribution: false,
    padding: 0,
    theme: 'dark',
    background: '$brand-primary',
    rolePermissions: {
      getWidgetNumberofTotalUsers: rolePermission, // set the role permission
    },
    filter: { tenant: { $eq: new ObjectId(currentTenantId) } },
    autoRefresh: false,
    maxDataAge: 300,
  });

  TotalUsers.propTypes = {
    rolePermission: PropTypes.array.isRequired,
    currentTenantId: PropTypes.string.isRequired,
  };

  useEffect(() => {
    async function renderChart() {
      try {
        await chart.render(chartDiv.current);
        const data = await chart.getData(); // Get the chart data

        if (data.documents.length === 0) {
          chartDiv.current.innerHTML = "<div class='not-found'>No Data Found</div>";
        }
      } catch (err) {
        console.log('Error during Charts rendering.', err);
      }
    }

    renderChart();
  }, []);

  return <div className="chart-container" ref={chartDiv}></div>;
};

export default TotalUsers;
