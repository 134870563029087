import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { RESERVE_NOW } from 'actions/chargerOcpp';
import Select from 'components/inputs/Select';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { GET_STATION_CHARGER_OPERATIONS, MANAGE_STATION_CHARGER_OPERATIONS } from 'components/common/constant';

const ReserveNowForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const chargerDetailData = useSelector((state) => state.charger.chargerDetails);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const getReserveNow = useCallback((data, resetForm) => {
    dispatch({
      type: RESERVE_NOW.REQUEST,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Reserve now successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const getStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const manageStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const ReserveNowSchema = Yup.object().shape({
    connectorId: Yup.number().required(t('reserveNowFom.connectorId')),
    expiryDate: Yup.string().required(t('reserveNowFom.expiryDate')),
    expiryTime: Yup.string().required(t('reserveNowFom.expiryTime')),
    idTag: Yup.string().required(t('reserveNowFom.idTag')).trim(t('reserveNowFom.space')).strict(true),
    // parentIdTag: Yup.string().required('Parent id tag is required!').trim('Space is not allowed').strict(true),
    reservationId: Yup.number().required(t('reserveNowFom.reservationId')),
  });

  const initialValues = {
    connectorId: '',
    expiryDate: '',
    expiryTime: '',
    idTag: '',
    parentIdTag: '',
    reservationId: '',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">{t('reserveNowFom.formTitle')}</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={ReserveNowSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const retrieveDateMomentObj = moment(values.expiryDate + values.expiryTime, 'YYYY-MM-DDLT');
                const expiryDate = retrieveDateMomentObj.utc();
                const formData = {
                  ...values,
                  id: chargerId,
                  expiryDate: expiryDate,
                };
                const reserveNowFormData = _.omit(formData, ['expiryTime']);
                getReserveNow(reserveNowFormData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col md={12}>
                        <Select
                          isRequired
                          label={t('reserveNowFom.connectorName')}
                          placeholder={t('placeHolder.selectConnector')}
                          options={_.map(_.get(chargerDetailData, 'plugs', []), (item) => {
                            return { label: `${item.name} - ${item.connector_id}`, value: item.connector_id };
                          })}
                          name="connectorId"
                          value={values.connectorId}
                          onChange={(val) => setFieldValue(`connectorId`, val)}
                          error={errors.connectorId && touched.connectorId ? errors.connectorId : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label={t('reserveNowFom.expiryDateLabel')}
                          type="date"
                          value={values.expiryDate}
                          name="expiryDate"
                          onChange={handleChange}
                          error={errors.expiryDate && touched.expiryDate ? errors.expiryDate : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label={t('reserveNowFom.expiryTimeLabel')}
                          type="time"
                          value={values.expiryTime}
                          name="expiryTime"
                          onChange={handleChange}
                          error={errors.expiryTime && touched.expiryTime ? errors.expiryTime : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label={t('reserveNowFom.idTagLabel')}
                          placeholder="Id tag"
                          value={values.idTag}
                          name="idTag"
                          onChange={handleChange}
                          error={errors.idTag && touched.idTag ? errors.idTag : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          // isRequired
                          label={t('reserveNowFom.parentIdTag')}
                          placeholder={t('reserveNowFom.parentIdTag')}
                          value={values.parentIdTag}
                          name="parentIdTag"
                          onChange={handleChange}
                          // error={errors.parentIdTag && touched.parentIdTag ? errors.parentIdTag : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label={t('reserveNowFom.reservationIdLabel')}
                          placeholder={t('reserveNowFom.reservationIdLabel')}
                          type="number"
                          value={values.reservationId}
                          name="reservationId"
                          onChange={handleChange}
                          error={errors.reservationId && touched.reservationId ? errors.reservationId : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    {!_.isEmpty(manageStationChargerOperations) ||
                    (!_.isEmpty(getStationChargerOperations) && !_.isEmpty(manageStationChargerOperations)) ? (
                      <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                        {t('reserveNowFom.button')}
                      </Button>
                    ) : (
                      <Button className="serverOperation-save-btn save--btn">{t('reserveNowFom.button')}</Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default ReserveNowForm;
