import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import tEn from './locales/en/translations.json';
import listsEn from './locales/en/lists.json';
import formsEn from './locales/en/forms.json';
import commonEn from './locales/en/common.json';
import reportsEn from './locales/en/reports.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...listsEn,
        ...formsEn,
        ...commonEn,
        ...reportsEn,
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
