import { DOWNLOAD_BRV_REPORT , DOWNLOAD_JV_REPORT , GET_BRV_REPORTS , GET_JV_REPORTS } from 'actions/smcGateway';

const initialValues = {
  isLoading: true,
  pdfpayload: '',
  reports :''
};

const smcReportsReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case GET_BRV_REPORTS.REQUEST:
    case GET_JV_REPORTS.REQUEST:
    case DOWNLOAD_BRV_REPORT.REQUEST:
    case DOWNLOAD_JV_REPORT.REQUEST:
    {
      return { ...state, isLoading: true };
    }

    case GET_BRV_REPORTS.SUCCESS:
    case GET_JV_REPORTS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        reports: payload,
      };
    }

    case DOWNLOAD_BRV_REPORT.SUCCESS:
    case DOWNLOAD_JV_REPORT.SUCCESS:
      {
        return {
          ...state,
          isLoading: false,
          pdfpayload : payload,
        };
      }

    case GET_BRV_REPORTS.FAIL:
      case GET_JV_REPORTS.FAIL: 
      case DOWNLOAD_BRV_REPORT.FAIL:
        case DOWNLOAD_JV_REPORT.FAIL:

    
    {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};

export default smcReportsReducer;
