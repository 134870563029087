import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_TARIFF, CREATE_TARIFF, DELETE_TARIFF, UPDATE_TARIFF, GET_TARIFF, UPDATE_TARIFF_SLOT } from 'actions/tariff';

function* fetchTariff(action) {
  try {
    const response = yield call(API.getTariff, action.payload);
    yield put({ type: FETCH_TARIFF.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_TARIFF.FAIL, payload: { error: e } });
  }
}

function* getTariff(action) {
  try {
    const response = yield call(API.getTariffById, action.payload);
    yield put({ type: GET_TARIFF.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_TARIFF.FAIL, payload: { error: e } });
  }
}

function* createTariff(action) {
  try {
    const response = yield call(API.addTariff, action.payload);
    yield put({ type: CREATE_TARIFF.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_TARIFF.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateTariff(action) {
  try {
    const response = yield call(API.updateTariff, action.payload);
    yield put({ type: UPDATE_TARIFF.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TARIFF.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}
function* updateTariffSlot(action) {
  try {
    const response = yield call(API.updateTariffSlot, action.payload);
    yield put({ type: UPDATE_TARIFF.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TARIFF.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteTariff(action) {
  try {
    const response = yield call(API.deleteTariff, action.payload);
    yield put({ type: DELETE_TARIFF.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_TARIFF.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* tariff() {
  yield takeLatest(FETCH_TARIFF.REQUEST, fetchTariff);
  yield takeLatest(GET_TARIFF.REQUEST, getTariff);
  yield takeLatest(CREATE_TARIFF.REQUEST, createTariff);
  yield takeLatest(UPDATE_TARIFF.REQUEST, updateTariff);
  yield takeLatest(UPDATE_TARIFF_SLOT.REQUEST, updateTariffSlot);
  yield takeLatest(DELETE_TARIFF.REQUEST, deleteTariff);
}
export default tariff;
