import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { DOWNLOAD_CHARGING_SESSION_REPORT, CHARGING_SESSION_REPORT } from 'actions/dataReport';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { FETCH_CUSTOMER } from 'actions/customer';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { roundOfDigit } from 'components/common/utils';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { IoSearchOutline } from 'react-icons/io5';
import fileDownload from 'js-file-download';
import ReactPagination from 'components/general/ReactPagination';
import { Formik, Form } from 'formik';
import Select from 'components/inputs/Select';
import { MdClear } from 'react-icons/md';
import OffCanvas from 'components/inputs/OffCanvas';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';

const ChargingSessionReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));

  const chargingSessionReportList = useSelector((state) => state.chargingSessionReport.ChargingSessionReport);
  const isLoader = useSelector((state) => state.chargingSessionReport.isLoading);
  const page = useSelector((state) => state.chargingSessionReport.page);
  const totalData = useSelector((state) => state.chargingSessionReport.total);
  const limit = useSelector((state) => state.chargingSessionReport.limit);
  const totalPages = useSelector((state) => state.chargingSessionReport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const chargingSessionReport = useCallback(
    (value) => {
      if (searchText) {
        const chargingSessionReportData = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
          is_emsp_based_booking: false,
        };
        dispatch({
          type: CHARGING_SESSION_REPORT.REQUEST,
          payload: chargingSessionReportData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: CHARGING_SESSION_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      const data = {
        ...advanceFilterData,
        page: page.selected + 1,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      chargingSessionReport(data);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      chargingSessionReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, searchText, advanceFilterData]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    chargingSessionReport({});
  };

  useEffect(() => {
    chargingSessionReport();
  }, []);

  // Download Excel Functionality

  const downloadUserChargingHistory = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      const data = {
        ...advanceFilterData,
        excel: true,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_CHARGING_SESSION_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Charging Session Report'}.xlsx`);
            }
          }
        },
      });
    } else if (searchText) {
      const data = {
        excel: true,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        search: searchText,
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_CHARGING_SESSION_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Charging Session Report'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_CHARGING_SESSION_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Charging Session Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData, searchText]);

  const downloadFile = () => {
    downloadUserChargingHistory();
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && chargingSessionReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        chargingSessionReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && chargingSessionReport(data);
      } else if (searchText) {
        const data = {
          search: searchText,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && chargingSessionReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        chargingSessionReport(data);
      }
    },
    [startDate, endDate, searchText]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        const data = {
          ...advanceFilterData,
          page: page.selected + 1,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        chargingSessionReport(data);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
          page: page.selected + 1,
        };
        chargingSessionReport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        chargingSessionReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        chargingSessionReport(data);
      }
    },
    [sortByItem, startDate, endDate, searchText, advanceFilterData]
  );

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const usersData = useSelector((state) => state.customer.customers);
  const usersPage = useSelector((state) => state.customer.page);
  const usersTotalPages = useSelector((state) => state.customer.totalPages);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      // access_type: 'Public',
    };
    dispatch({ type: GET_STATION_BY_CITY.REQUEST, payload: stationData });
  }, []);

  const getAllUsers = useCallback((data = {}) => {
    dispatch({ type: FETCH_CUSTOMER.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getAllStation();
    getAllUsers();
  }, []);

  const chargingType = [
    { label: 'Auto Charge', value: 'autocharge' },
    { label: 'RFID', value: 'rfid' },
    { label: 'Mobile APP', value: 'mobileapp' },
  ];

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { is_ocpi_based_booking: '', partyId: '' };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.chargingSessionReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment(new Date()).startOf('day')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block">
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon" onClick={downloadFile}>
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.fleetMemberName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.mobileNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stationName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.ocppID')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.idTag')}</span>
                                <span className="ico" onClick={() => handleSorting('idTag', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.transactionId')}</span>
                                <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.rfidCardNo')}</span>
                                <span className="ico" onClick={() => handleSorting('rfid_card_number', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.unitConsumed')}</span>
                                <span className="ico" onClick={() => handleSorting('energy_consumed', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.sessionDuration')}</span>
                                <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stopReason')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.energyRate')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.netAmount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.totalAmount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.chargingType')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.bookingId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.bookingDateTime')}</span>
                                <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.estimatedBookingAmount')}</span>
                              </div>
                            </th>
                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.chargecoinEstimated')}</span>
                              </div>
                            </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.bookingSlotTime')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={16} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(chargingSessionReportList) ? (
                            <tr>
                              <td colSpan={16} className="border-0">
                                <div className="empty-data-block">{t('dataReport.noChargingSessionReportData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(chargingSessionReportList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;

                              const unitConsumed = isNaN((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000)
                                ? '-'
                                : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);

                              const pricePerUnit = unitConsumed
                                ? isNaN(parseFloat(_.get(item, 'invoice.subtotal') / unitConsumed).toFixed(2))
                                  ? ''
                                  : parseFloat(_.get(item, 'invoice.subtotal') / unitConsumed).toFixed(2)
                                : '';

                              function calculateTimeDifference(start, end) {
                                const startTime = moment(start);
                                const stopTime = moment(end);

                                const timeDiffInMS = stopTime - startTime;

                                const seconds = moment.duration(timeDiffInMS).seconds();
                                const minutes = moment.duration(timeDiffInMS).minutes();
                                const hours = Math.trunc(moment.duration(timeDiffInMS).asHours());

                                if (Math.sign(timeDiffInMS) === -1) {
                                  return `-${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                } else {
                                  return `${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                }
                              }

                              let chargingType;
                              if (item.is_rfid_based_booking === true) {
                                chargingType = 'RFID';
                              } else if (item.is_autocharge_based_booking === true) {
                                chargingType = 'Auto Charge';
                              } else {
                                chargingType = 'Mobile App';
                              }

                              return (
                                <tr key={`charging-session-report-${serial_num}`}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.name', 'Guest User')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.phone', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'idTag', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_id', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'rfid_card_number') ? (
                                      _.get(item, 'rfid_card_number')
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : unitConsumed}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <>{calculateTimeDifference(item.booking_start, item.booking_stop)}</>
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'stop_reason', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.price_per_unit', pricePerUnit)}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'net_amount', ''), 2)}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'total_amount', ''), 2)}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : chargingType}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'id', '-')}</td>
                                  <td>
                                    {isLoading ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.booking_start ? (
                                      moment(item.booking_start).format('DD/MM/YYYY H:mm:ss')
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'estimated_amount', '-'), 2)}</td>
                                  {/* <td>
                                    {isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'chargecoins_estimated', '-'), 2)}
                                  </td> */}
                                  <td>
                                    {isLoading ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.schedule_datetime ? (
                                      moment(item.schedule_datetime).format('DD/MM/YYYY H:mm:ss')
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(chargingSessionReportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* App User Details Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.fleet_member && delete values['fleet_member'];
              !values.charging_station && delete values['charging_station'];
              !values.charging_type && delete values['charging_type'];
              chargingSessionReport(values);
              handleAdvanceFilterData(values);
              setSubmitting(false);
              onCloseAdvanceFilterPopup();
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('dataReport.fleetMember')}
                    options={[
                      ..._.map(usersData, (item) => {
                        return {
                          label: _.get(item, 'name', 'Guest User') + ' ' + `+${_.get(item, 'country_code')} ${_.get(item, 'phone')}`,
                          value: item.id,
                        };
                      }),
                    ]}
                    placeholder={t('placeHolder.selectFleetMember')}
                    name="fleet_member"
                    isMulti
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllUsers}
                    page={usersPage}
                    totalPage={usersTotalPages}
                    value={values.fleet_member}
                    onChange={(val) => {
                      setFieldValue('fleet_member', val);
                    }}
                  />
                  <Select
                    label={t('placeHolder.selectStations')}
                    options={[
                      ..._.map(allStationByCity, (station) => {
                        return { label: station.name, value: station.id };
                      }),
                    ]}
                    placeholder={t('placeHolder.selectStations')}
                    name="charging_station"
                    isMulti
                    value={values.charging_station}
                    onMenuScrollDown={true}
                    onChange={(val) => {
                      setFieldValue(`charging_station`, val);
                    }}
                  />
                  <Select
                    label={t('placeHolder.selectChargingType')}
                    options={[
                      ..._.map(chargingType, (data) => {
                        return { label: data.label, value: data.value };
                      }),
                    ]}
                    placeholder={t('placeHolder.selectChargingType')}
                    name="charging_type"
                    value={values.charging_type}
                    onMenuScrollDown={true}
                    onChange={(val) => {
                      setFieldValue(`charging_type`, val);
                    }}
                  />
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      resetForm();
                      chargingSessionReport();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default ChargingSessionReport;
