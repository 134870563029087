import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { COUPON_PERFORMANCE_REPORT, DOWNLOAD_COUPON_PERFORMANCE_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { roundOfDigit } from 'components/common/utils';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BsDownload } from 'react-icons/bs';
import { IoSearchOutline } from 'react-icons/io5';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import ReactPagination from 'components/general/ReactPagination';
import fileDownload from 'js-file-download';
//import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { useLocation, useNavigate } from 'react-router-dom';

const CouponPerformanceReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [couponState] = useState(location.state || {});
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    if (couponState?.startDate) {
      return moment(couponState?.startDate).utc();
    }
    return moment(new Date()).startOf('month');
  });

  const [endDate, setEndDate] = useState(() => {
    if (couponState?.endDate) {
      return moment(couponState?.endDate).utc();
    }
    return moment(new Date()).endOf('day');
  });

  const couponUsageList = useSelector((state) => state.couponPerformace.couponPerformanceReport);
  const isLoader = useSelector((state) => state.couponPerformace.isLoading);
  const page = useSelector((state) => state.couponPerformace.page);
  const totalData = useSelector((state) => state.couponPerformace.total);
  const limit = useSelector((state) => state.couponPerformace.limit);
  const totalPages = useSelector((state) => state.couponPerformace.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');

  const couponId = couponState?.couponId;

  const couponPerformanceReport = useCallback(
    (value) => {
      if (searchText) {
        const couponPerformanceReportData = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
        };
        dispatch({
          type: COUPON_PERFORMANCE_REPORT.REQUEST,
          payload: couponPerformanceReportData,
        });
      } else if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
        };
        dispatch({
          type: COUPON_PERFORMANCE_REPORT.REQUEST,
          payload: data,
        });
      } else if (!_.isEmpty(couponId)) {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          couponId: couponId,
        };
        dispatch({
          type: COUPON_PERFORMANCE_REPORT.REQUEST,
          payload: data,
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: COUPON_PERFORMANCE_REPORT.REQUEST,
          payload: data,
        });
      }
    },
    [startDate, endDate, searchText]
  );

  useEffect(() => {
    const initialReportData = {};
    if (couponState?.startDate && couponState?.endDate) {
      initialReportData.from = moment(couponState?.startDate).utc();
      initialReportData.to = moment(couponState?.endDate).utc();
    }
    couponPerformanceReport(initialReportData);
  }, [couponPerformanceReport]);

  useEffect(() => {
    if (location.state) {
      navigate(location.pathname, { replace: true });
    }
  }, []);

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
      };
      couponPerformanceReport(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      couponPerformanceReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, searchText]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  const handleSearch = () => {
    couponPerformanceReport({});
  };

  useEffect(() => {
    if (!_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      couponPerformanceReport(data);
    } else {
      couponPerformanceReport();
    }
  }, []);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && couponPerformanceReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        couponPerformanceReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && couponPerformanceReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        couponPerformanceReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        couponPerformanceReport(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        couponPerformanceReport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        couponPerformanceReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        couponPerformanceReport(data);
      }
    },
    [sortByItem, startDate, endDate, searchText]
  );

  const downloadcouponPerformanceReport = useCallback(() => {
    const data = {
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
      charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      excel: true,
      couponId: !_.isEmpty(couponId) ? couponId : '',
    };
    dispatch({
      type: DOWNLOAD_COUPON_PERFORMANCE_REPORT.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Coupon Performance Report'}.xlsx`);
          }
        }
      },
    });
  }, [startDate, endDate]);

  const downloadFile = () => {
    downloadcouponPerformanceReport();
  };

  const search = (
    <div className="search-box-option">
      <SearchBox
        preIcon={<BsSearch />}
        value={searchText}
        onChange={searchHandler}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
    </div>
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.couponPerformanceReport')} className="coupon_performance_report" />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={startDate}
                            initialValueOfEndDate={endDate}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponPerformanceReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponPerformanceReport.couponCode')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponPerformanceReport.totalUsed')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponPerformanceReport.totalDiscountAvail')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponPerformanceReport.totalRevenue')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponPerformanceReport.redemptionType')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponPerformanceReport.startDateTime')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponPerformanceReport.endDateTime')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(couponUsageList) ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="empty-data-block">{t('couponPerformanceReport.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(couponUsageList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              return (
                                <tr key={`coupon-usage-${serial_num}`}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'name', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'total_used', ''), 2)}</td>
                                  <td>
                                    {isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'total_discount_availed', ''), 2)}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'total_revenue', ''), 2)}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'redemption_type', '')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.start_date_time ? (
                                      moment(item.start_date_time).format('DD/MM/YYYY H:mm:ss')
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.end_date_time ? (
                                      moment(item.end_date_time).format('DD/MM/YYYY H:mm:ss')
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(couponUsageList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};

export default CouponPerformanceReport;
