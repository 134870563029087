import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_CPO,
  UPDATE_CPO,
  FETCH_CPO,
  DELETE_CPO,
  GET_CPO,
  PULL_LOCATION,
  HANDSHAKE,
  // VIEW_ENDPOINTS,
  PULL_SESSIONS,
  PULL_TARIFF,
  PULL_TOKEN,
  PULL_CDRS,
  GET_ENDPOINT,
  GET_LOCATION,
  ADD_LOCATION,
  REMOVE_LOCATION,
} from 'actions/partnerManagement';

function* createCpo(action) {
  try {
    const response = yield call(API.addCpo, action.payload);
    yield put({ type: CREATE_CPO.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CPO.FAIL, payload: { error: e } });
  }
}

function* getCpo(action) {
  try {
    const response = yield call(API.getCpoData, action.payload);
    yield put({ type: GET_CPO.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_CPO.FAIL, payload: { error: e } });
  }
}

function* fetchCpo(action) {
  try {
    const response = yield call(API.fetchCpoData, action.payload);
    yield put({ type: FETCH_CPO.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CPO.FAIL, payload: { error: e } });
  }
}

function* updateCpo(action) {
  try {
    const response = yield call(API.updateCpo, action.payload);
    yield put({ type: UPDATE_CPO.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CPO.FAIL, payload: { error: e } });
  }
}

function* deleteCpo(action) {
  try {
    const response = yield call(API.removeCpo, action.payload);
    yield put({ type: DELETE_CPO.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CPO.FAIL, payload: { error: e } });
  }
}

// OCPI Admin operation

function* handshaking(action) {
  try {
    const response = yield call(API.handshake, action.payload);
    yield put({ type: HANDSHAKE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: HANDSHAKE.FAIL, payload: { error: e } });
  }
}

function* getEndPointByToken(action) {
  try {
    const response = yield call(API.getEndpointByToken, action.payload);
    yield put({ type: GET_ENDPOINT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_ENDPOINT.FAIL, payload: { error: e } });
  }
}

function* pullLucation(action) {
  try {
    const response = yield call(API.pullLucations, action.payload);
    yield put({ type: PULL_LOCATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_LOCATION.FAIL, payload: { error: e } });
  }
}

function* pullTariff(action) {
  try {
    const response = yield call(API.pullTariffs, action.payload);
    yield put({ type: PULL_TARIFF.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_TARIFF.FAIL, payload: { error: e } });
  }
}

function* pullSession(action) {
  try {
    const response = yield call(API.pullSession, action.payload);
    yield put({ type: PULL_SESSIONS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_SESSIONS.FAIL, payload: { error: e } });
  }
}

function* pullCdrs(action) {
  try {
    const response = yield call(API.pullCdrs, action.payload);
    yield put({ type: PULL_CDRS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_CDRS.FAIL, payload: { error: e } });
  }
}

function* pullToken(action) {
  try {
    const response = yield call(API.token, action.payload);
    yield put({ type: PULL_TOKEN.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_TOKEN.FAIL, payload: { error: e } });
  }
}

function* getlocation(action) {
  try {
    const response = yield call(API.getLocations, action.payload);
    yield put({ type: GET_LOCATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_LOCATION.FAIL, payload: { error: e } });
  }
}

function* updateLocation(action) {
  try {
    const response = yield call(API.updateLocation, action.payload);
    yield put({ type: ADD_LOCATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ADD_LOCATION.FAIL, payload: { error: e } });
  }
}

function* removeLocation(action) {
  try {
    const response = yield call(API.removeLocation, action.payload);
    yield put({ type: REMOVE_LOCATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: REMOVE_LOCATION.FAIL, payload: { error: e } });
  }
}

function* partnerManagement() {
  yield takeLatest(CREATE_CPO.REQUEST, createCpo);
  yield takeLatest(GET_CPO.REQUEST, getCpo);
  yield takeLatest(FETCH_CPO.REQUEST, fetchCpo);
  yield takeLatest(UPDATE_CPO.REQUEST, updateCpo);
  yield takeLatest(DELETE_CPO.REQUEST, deleteCpo);
  yield takeLatest(PULL_LOCATION.REQUEST, pullLucation);
  yield takeLatest(HANDSHAKE.REQUEST, handshaking);
  yield takeLatest(GET_ENDPOINT.REQUEST, getEndPointByToken);
  yield takeLatest(PULL_SESSIONS.REQUEST, pullSession);
  yield takeLatest(PULL_TARIFF.REQUEST, pullTariff);
  yield takeLatest(PULL_TOKEN.REQUEST, pullToken);
  yield takeLatest(PULL_CDRS.REQUEST, pullCdrs);
  yield takeLatest(GET_LOCATION.REQUEST, getlocation);
  yield takeLatest(ADD_LOCATION.REQUEST, updateLocation);
  yield takeLatest(REMOVE_LOCATION.REQUEST, removeLocation);
}
export default partnerManagement;
