import { createActionTypes } from 'utils/action/actionTypes';

export const CREATE_CPO = createActionTypes('CREATE_CPO');
export const FETCH_CPO = createActionTypes('FETCH_CPO');
export const GET_CPO = createActionTypes('GET_CPO');
export const UPDATE_CPO = createActionTypes('UPDATE_CPO');
export const DELETE_CPO = createActionTypes('DELETE_CPO');
export const HANDSHAKE = createActionTypes('HANDSHAKE');
export const VIEW_ENDPOINTS = createActionTypes('VIEW_ENDPOINTS');
export const GET_ENDPOINT = createActionTypes('GET_ENDPOINT');
export const PULL_LOCATION = createActionTypes('PULL_LOCATION');
export const PULL_SESSIONS = createActionTypes('PULL_SESSIONS');
export const PULL_TARIFF = createActionTypes('PULL_TARIFF');
export const PULL_TOKEN = createActionTypes('PULL_TOKEN');
export const PULL_CDRS = createActionTypes('PULL_CDRS');
export const GET_LOCATION = createActionTypes('GET_LOCATION');
export const ADD_LOCATION = createActionTypes('ADD_LOCATION');
export const REMOVE_LOCATION = createActionTypes('REMOVE_LOCATION');

