import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_COUPONS, DELETE_COUPON, UPDATE_COUPON, CREATE_COUPON, GET_COUPON, BOOKING_COUPON } from 'actions/coupon';

function* fetchCoupons(action) {
  try {
    const response = yield call(API.getCoupons, action.payload);
    yield put({ type: FETCH_COUPONS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_COUPONS.FAIL, payload: { error: e } });
  }
}

function* getCoupon(action) {
  try {
    const response = yield call(API.getCouponById, action.payload);
    yield put({ type: GET_COUPON.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_COUPON.FAIL, payload: { error: e } });
  }
}

function* createCoupon(action) {
  try {
    const response = yield call(API.addCoupon, action.payload);
    yield put({ type: CREATE_COUPON.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_COUPON.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateCoupon(action) {
  try {
    const response = yield call(API.updateCoupon, action.payload);
    yield put({ type: UPDATE_COUPON.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_COUPON.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteCoupon(action) {
  try {
    const response = yield call(API.deleteCoupon, action.payload);
    yield put({ type: DELETE_COUPON.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_COUPON.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}
function* validateCoupon(action) {
  try {
    const response = yield call(API.validateCoupon, action.payload);
    yield put({ type: BOOKING_COUPON.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: BOOKING_COUPON.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* coupon() {
  yield takeLatest(FETCH_COUPONS.REQUEST, fetchCoupons);
  yield takeLatest(GET_COUPON.REQUEST, getCoupon);
  yield takeLatest(CREATE_COUPON.REQUEST, createCoupon);
  yield takeLatest(UPDATE_COUPON.REQUEST, updateCoupon);
  yield takeLatest(DELETE_COUPON.REQUEST, deleteCoupon);
  yield takeLatest(BOOKING_COUPON.REQUEST, validateCoupon);
}

export default coupon;