export const pxToRem = (pixels) => `${pixels / 16}rem`;

export const theme = {
  colors: {
    // Core Brand Colors
    brand: {
      primary: '#1b2a38',
      secondary: '#223343',
      accent: '#be210b',
    },

    // Semantic Colors
    semantic: {
      success: '#0f9b72',
      warning: '#f2de10',
      error: '#dc4949',
      info: '#4975F2',
    },

    // Grays
    gray: {
      50: '#F7F7F8',
      100: '#B9C8D3',
      200: '#8d949b',
      300: '#5f7384',
      400: '#3b4a56',
      500: '#29343e',
      600: '#182532',
      700: '#15212b',
      800: '#1C2A3B',
    },

    // UI Elements
    ui: {
      activity: {
        iconBlockBorder: '#394f63',
      },
      input: {
        label: '#ffffff80',
        border: '#3b4a56',
        value: '#ffffff',
        disabled: '#223444',
        focus: '#979797',
      },
      button: {
        primary: '#be210b',
        primaryHover: '#bb2d3b',
        secondary: '#36303a',
        secondaryText: '#f5604c',
      },
      sidebar: {
        menuItem: '#8d9dac',
        hoverBg: '#1e3349',
      },
      table: {
        header: '#ffffff80',
        subRowBg: '#60606021',
      },
      scrollbar: '#c3c2c566',
      toggle: {
        default: '#ccc',
        checked: '#0f9b72',
      },
      tab: {
        active: '#1e3249',
      },
      statusBlock: {
        hover: '#1b2a38ad',
      },
    },

    // Status Colors
    status: {
      charger: {
        yellow: '#f2de10',
        iconBg: '#e85c481a',
      },
      breathing: {
        blue: '#3c7cdd1a',
        red: '#be210b21',
      },
    },

    // Light Mode
    light: {
      bg: {
        primary: '#f0f0f0',
        secondary: '#ffffff',
        content: '#eef7ff',
      },
      text: {
        primary: '#333333',
        secondary: '#8d949b',
      },
      input: {
        label: '#333333',
        border: '#dddddd',
        value: '#333333',
        disabled: '#999999',
      },
      sidebar: {
        hover: '#f0faff',
        afterHover: '#eef7ff',
      },
      table: {
        header: '#000000',
      },
    },

    // Special Purpose
    special: {
      rating: '#fbaa26',
      excel: '#0f9b72',
      admin: '#808080',
      billing: '#ffffff1a',
    },

    // Profile Types
    profileTypes: ['#1C2A3B', '#363CAA', '#800DA3', '#A12941', '#FB6107', '#F7AD00', '#326771', '#129387', '#608FC9'],

    // User Groups
    userGroups: ['#365173', '#8B1D68', '#B941DD', '#68CCDF', '#565EFC', '#F3486A', '#30B04D', '#BADA4E', '#FFCB47'],

    // Brand Secondary
    brandSecondary: ['#42648D', '#A12941', '#F3486A', '#449EAE', '#8C56FC', '#FF7D00', '#30B06B', '#00A0E9', '#4600D7'],

    // Member Types
    memberTypes: {
      frequentVisitor: '#a12941',
      home: '#8f8f8f',
      jmOffice: '#129387',
      mCampus: '#608fc9',
      mMaritime: '#1c2a3b',
      visitor: '#cc173b',
    },

    // Spaces
    spaces: ['#1c2a3b', '#59636F', '#88AAD3', '#7E8995', '#9DA9B5', '#ededed', '#E3B2BF', '#D096A1', '#BA6B7A', '#a12941'],
  },

  // Typography
  typography: {
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
    },
    sizes: {
      xs: pxToRem(14),
      sm: pxToRem(16),
      md: pxToRem(18),
      header: pxToRem(24),
    },
  },

  // Layout
  layout: {
    header: {
      height: pxToRem(64),
    },
    sidebar: {
      width: pxToRem(288),
    },
    toolbar: {
      width: pxToRem(324),
    },
  },

  // Spacing
  spacing: {
    xs: pxToRem(4),
    sm: pxToRem(8),
    md: pxToRem(16),
    lg: pxToRem(26),
    outline: pxToRem(28),
  },

  // Sizes
  sizes: {
    xs: pxToRem(16),
    sm: pxToRem(19.2),
    md: pxToRem(24),
    lg: pxToRem(48),
  },

  // Border
  border: {
    radius: {
      sm: pxToRem(8),
      md: pxToRem(16),
      lg: pxToRem(24),
    },
    width: {
      sm: '1px',
      md: '2px',
    },
  },
};

export default { theme, pxToRem };
